

.swipe-container{

    width: 100%;
    height: 100vh;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    display: flex;

    section{
        flex: none;
        height: 100vh;
        width: 100vw;
        scroll-snap-align: start;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;
        position: relative;

        .expand-btn{
            position: absolute;
            bottom: 20%;
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%);

            width: 150px;
            height: 50px;
            border-radius: 5px;
            border: none;
            display: grid;
            place-items: center;

            font-family: carbon-bold;
            font-size: 2rem;
        }
        h1{
            font-size: 10rem;
        }
        h4{
            opacity: (0.5);
            font-size: 1.2rem;
            text-transform: uppercase;
            letter-spacing: 0.1rem;
            padding-bottom: 20%;
        }
    }
}

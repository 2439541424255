
.footer{
    position: relative;
    z-index: 2;
    height: 100px;
    width: 100%;
    padding: 0 1.5rem;
    background: #FBFBFD;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top-layer{
        p{
            font-family: carbon-bold;
            font-weight: normal;
            font-size: 1rem;
            color: $dark;
        }
    }

    border-top: 0.5px solid hsla(0deg, 20%, 5%, 0.1);
}



@media only screen and (min-width: 1200px) {
 .footer{
    padding: 0 5rem;
    height: 8rem;
 }
}


@media only screen and (min-width: 1500px) {
    .footer{
       padding: 0 14%;
       height: 8rem;
    }
}
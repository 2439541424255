///////  p r e p  ///////
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

img {
    width: 100%;
    height: auto;
}

html {
    scroll-behavior: smooth;
  }

///////  s c r e e n s  ///////
$mobile : 480px;
$tablet : 768px;
$laptop : 992px;
$desktop : 1200px;


@font-face {
    src: url(../public/assets/fonts/carbon-bold/bold.ttf);
    font-family: carbon-bold;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
$os: 'Open Sans', sans-serif;


///////  c o l o r s  ///////
$light: #EEEEEE;
$blue: #3454D1;
$green: #20A39E;
$red: #FE5F55;
$dark: #0F0A0A;
$grey: #8E8E8E;
$white: #ffffff;
$black: #000000;
$black2: #101010;

$blockify: #3454D1;
$moonlog: #121212;

$white-80: hsla(0, 0%, 100%, 0.8);
$white-65: hsla(0, 0%, 100%, 0.65);
$white-50: hsla(0, 0%, 100%, 0.5);
$white-30: hsla(0, 0%, 100%, 0.3);
$white-20: hsla(0, 0%, 100%, 0.2);
$white-10: hsla(0, 0%, 100%, 0.1);

$blue-80: hsla(228, 63%, 51%, 0.8);
$dark-90: hsla(0, 20%, 5%, 0.9);
$dark-80: hsla(0, 20%, 5%, 0.8);
$dark-65: hsla(0, 20%, 5%, 0.65);
$dark-50: hsla(0, 20%, 5%, 0.5);
$dark-35: hsla(0, 20%, 5%, 0.35);
$dark-20: hsla(0, 20%, 5%, 0.2);
$dark-10: hsla(0, 20%, 5%, 0.1);
$dark-05: hsla(0, 20%, 5%, 0.05);

$grey-50: hsla(0, 0%, 56%, 0.5);
$grey-10: hsla(0, 0%, 56%, 0.1);

$blue-50: hsla(228, 63%, 51%, 0.5);

$black2-80: hsla(0, 0%, 6%, 0.8%);
$black2-50: hsla(0, 0%, 6%, 0.5%);


///////  u x 1 0 0  ///////
$ux100: #AD4C7D;
$ligth-v2: #FBFBFD;


///////  a b o u t  ///////
$light-bg: #FBFBFD;
$light-share: #FBFBFB;
$light-share2: #F4F3FF;
$blue-share: #2253CA;

$light-share2: #F8F8F8;
$blue-share: $dark-80;
// $blue-share: #2253CA;

$shadow: 0 0 8px 0 $dark-20;
$shadow-logo: 0 0 4px 0 $dark-20;


$box-shadow: 0 0 8px 0 hsla(0, 20%, 5%, 0.2);
$box-shadow-hover: 0 0 20px 0px $dark-20;

$spacing-phone: 1.5rem;
$spacing-mobile: 3rem;
$spacing-tablet: 5rem;
$spacing-laptop: 10%;
$spacing-desktop: 20%;

h1 {
    font-family: carbon-bold;
    font-weight: normal;
    color: $dark;
}

h2 {
    font-family: $os;
    font-weight: 600;
    text-transform: capitalize;
}

// section head title
h3 {
    font-family: $os;
    font-weight: 300;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    color: $dark-65;
}

// section sub title
h4 {
    font-family: carbon-bold;
    font-weight: normal;
    font-size: 1.5rem;
    color: $dark;
    letter-spacing: -0.08rem;
    text-transform: capitalize;
}

h5 {
    font-family: $os;
    font-weight: 600;
    font-size: 1.2rem;
}

h6 {
    font-family: $os;
    font-weight: 600;
    font-size: 1rem;
}

p {
    font-family: $os;
    font-size: 1rem;
    font-weight: 400;
    color: $dark-65;
    line-height: 180%;
}

body {
    background: $light-bg;
}


// responsive
$p-mobile: 5%;



// stylesheets
@import './styles/Home.scss';
@import './styles/Trainings.scss';
@import './styles/About.scss';


@import './styles/BehaviorPatterns.scss';
@import './styles/Book.scss';
@import './styles/Button.scss';
@import './styles/Cards.scss';
@import './styles/CaseHero.scss';
@import './styles/Footer.scss';
@import './styles/HomeHero.scss';
@import './styles/Modal.scss';
@import './styles/Navbar.scss';
@import './styles/Read.scss';
@import './styles/SectionHead.scss';
@import './styles/SectionIntro.scss';
@import './styles/Swipe.scss';
@import './styles/Test.scss';
@import './styles/Trainings.scss';
@import './styles/BookSection.scss';
@import './styles/CourseSection.scss';
@import './styles/SkillsSection.scss';
@import './styles/Socials.scss';
@import './styles/AboutMe.scss';
@import './styles/Github.scss';
@import './styles/CaseStudy.scss';
@import './styles/CaseStructure.scss';
@import './styles/Cases.scss';
@import './styles/CaseUX100.scss';
@import './styles/Bigcard.scss';



// Desktop
@import './styles/HomeDesktop.scss';
@import './styles/TrainingsDesktop.scss';



// test
@import './styles/NewHome.scss'
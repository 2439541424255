


.books-section{
    padding: 0 1.5rem 1.5rem 1.5rem;
    width: 100%;

    .books-grid{
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;

        .books-card{
            position: relative;
            aspect-ratio: 1 / 1;
            cursor: pointer;

            border: 0px solid salmon;
            background: #F8F8F8;
            border-radius: 5px;
            box-shadow: 0 0 8px 0 hsla(0, 20%, 5%, 0.2);
            transition: box-shadow ease-out 300ms, transform ease-out 300ms;

            display: grid;
            place-items: center;
            
            img{
                height: 45%;
                width: auto;
                filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.2));
                transition: transform ease-out 300ms, filter ease-out 300ms,
            }
            .books-count{
                position: absolute;
                top: 5%; left: 5%;
                color: $dark-20;
            }
            .book-info{
                position: absolute;
                bottom: 10%;

                text-align: center;
                transition: transform ease-out 300ms;

                .book-title{
                    font-size: 1rem;
                    text-transform: lowercase;
                    color: $dark-80;
                    margin-bottom: 0.85rem;
                }
                .book-author{
                    font-size: 0.85rem;
                    color: $dark-20
                }
            }
        }

        .books-card:hover{
            box-shadow: $box-shadow-hover;
            transform: scale(1.02);

            img{
                filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.3));
                transform: scale(1.04);
            }
            .book-info{
                transform: scale(1.04);
            }
        }
    }
}




@media only screen and (min-width: 1200px) {
    .books-section{
        padding: 0 14% 3rem 14%;
        .books-grid{
            grid-template-columns: 1fr 1fr;
            .books-card{
                // border-radius: 10px;
                img{
                    height: 52%;
                }
                .books-count{
                    font-size: 1.25 rem;
                }

                .book-info{
                    .book-title{
                        font-size: 1.25rem;
                    }
                    .book-author{
                        font-size: 0.85rem;
                    }
                }
            }
        }
    }
}












.modal{

    position: absolute;
    top: 10%; bottom: 10%;
    left: 0; right: 0;

    .modalcontainer{
        height: 100%;
        position: relative;
        border: 1px solid grey;
        
        .modaldata{

            padding: 1.5rem;
            img{
                width: 50px;
                height: auto;
            }
            h2{
                margin-bottom: 1.5rem;
            }
            .infoflex{
                display: flex;
                flex-direction: column;
                border: 1px solid salmon;
    
                .info{
                    border: 1px solid green;
                    display: flex;
                    width: 100%;
    
                    .minititle{
                        flex: 2;
                        border: 1px solid red;
                    }
                    .minicontent{
                        border: 1px solid violet;
                        flex: 5;
                    }
                }
            }
            .text{
                color: #737373;
            }
        }


        .modalbuttons{
            position: absolute;
            bottom: 0%;
            left: 0; right: 0;
            padding: 1.5rem;

            display: flex;
            justify-content: space-between;
            border: 1px solid orange;

            .closemodal, .moreinfo{
                height: 50px;
                width: 150px;
                border-radius: 10px;
                border: none;
            }
        }
    }
}
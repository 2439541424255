


.new-switch{
    padding: 4rem 1.5rem;
    text-align: center;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .facts-switch{
        width: 100%;
        line-height: 2.5rem;

        .switcher{
            color: $red;
            cursor: pointer;
            position: relative;
        }
        .switcher.active::before {
            transform: scaleX(1);
            transform-origin: left;
        }
        .switcher::before {
            background: #FFC5B2;
            content: "";
            inset: 0;
            position: absolute;
            top: -10%; left: 2%;
            bottom: -10%; right: -5%;
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.5s ease-in-out;
            z-index: -1;
        }
        .switcher:hover::before {
            transform: scaleX(1);
            transform-origin: left;
        }        
    }
}



.section-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.section-title{
    margin-bottom: 2rem;
    margin-top: 3rem;
}

.section-text{
    line-height: 2.5rem;
    text-align: center;
}

a{
    position: relative;
    text-decoration: none;
    .section-link{
        display: inline-block;
        color: $red;
    }

    .section-link::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: $red;
        transform-origin: bottom center;
        transition: transform 0.3s ease-out;
      }
      
      .section-link:hover::after {
        transform: scaleX(1);
        transform-origin: bottom center;
      }
}


.section-switch{
    border: 0px solid salmon;
    width: 100%;
    height: 5rem;
    display: flex;

    padding: 0 14%;

    div{
        background: lightgoldenrodyellow;
        border: 1px solid black;
        flex: 1;

        display: grid;
        place-items: center;
    }
}

.facts-section{
    background: #FBFBFD;
    // background: white;
    padding: 0;
}



@media (min-width: $desktop) {
    .new-switch{
        padding: 6rem 20%;
    }

    .section-content{
        align-items: flex-start;
        // text-align: center;
        .section-text{
            text-align: left;
        }
    }
}


.book{
    background: $ligth-v2;
    height: 100%;
    width: 100%;
    padding: 1.5rem;
    padding-bottom: 4rem;
    border: 0px solid olivedrab;

    .container{
        h4{
            margin-bottom: 1.5rem;
        }
        p{
            margin-bottom: 4rem;
        }
        img{
            margin-bottom: 4rem;
            padding: 0 2rem;
        }
    }
    .buttoncontainer{
        padding: 0 1.5rem;
        display: grid;
        place-items: center;
    }
}



@media only screen and (min-width: 1200px) {
    .projects{
        padding: 0rem $p-mobile 7rem $p-mobile;
        display: flex;
        flex-direction: column;

        .projectcard{
            margin-left: auto;
            margin-right: auto;
            max-width: 400px;

            .top-card .content-card .tabs-container{
                height: 1.6rem;
                padding: 0.5rem;

                .tabs-text{
                    font-size: 0.6rem;
                }
            }
        }
    }

    .projects-desk{
        position: relative;
        height: 100%;
        width: 100vw;
        border: 0px solid magenta;

        // background: white;
    
        ::-webkit-scrollbar {
            width: 1px;
        }
        ::-webkit-scrollbar-thumb {
            background: white;
            border-radius: 5px;
        }
        ::-webkit-scrollbar-thumb:hover {
            background: white;
        }

        .projects-desk-container{
            // margin: 0 5%;
            //smooth-scroll
            height: 100%;
            scroll-snap-type: y mandatory;
            overflow-y: auto;
            height: 100vh;

              
            .project-desk-sec{
                height: 100vh;
                scroll-snap-align: start;
                display: flex;
                padding: 0 10% 0 10%;
                background: white;
                
                .project-desk-text{
                    height: 100%;
                    width: 15rem;
                    padding: 0 10%;
                    border: 0px solid grey;
                    flex: 1;
                    
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
    
                    .project-desk-title{
                        font-size: 1.8rem;
                        color: $dark;
                    }
                    .project-desk-large{
                        font-size: 3rem;
                        font-weight: 200;
                        color: $dark-50;
                        margin: 2rem 0;
                    }
                    .project-desk-link{
                        font-size: 1.2rem;
                        color: $dark-80;
                        text-transform: lowercase;
    
                        display: inline-block;
                        position: relative;
                    }
    
                    .project-desk-link::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        transform: scaleX(0);
                        height: 2px;
                        bottom: 0;
                        left: 0;
                        background-color: $dark-80;
                        transform-origin: bottom center;
                        transition: transform 0.3s ease-out;
                      }
                      
                      .project-desk-link:hover::after {
                        transform: scaleX(1);
                        transform-origin: bottom center;
                      }
        
                }
                .project-desk-image{
                    height: 100%;
                    width: 15rem;
                    border: 0px solid grey;
                    flex: 1;
        
                    display: grid;
                    place-items: center;
        
                    .desk-card{
                        border: 0px solid red;
        
                        position: relative;
                        cursor: pointer;
                        width: 25rem;
                        height: 30rem;
        
          
                        .desk-bottom-card{
                            position: absolute;
                            top: 6px; right: -6px;
                            bottom: -6px; left: 6px;
                            background: none;
                            border-radius: 1rem;
                            border: 1.5px solid hsl(0, 0%, 0%, 30%);
                        }
    
        
                        .desk-top-card{
                            position: absolute;
                            top: 0; right:0;
                            bottom: 0; left: 0;
                            background: white;
                            border-radius: 1rem;
                            border: 1.5px solid $dark-80;
                            overflow: hidden;
                
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-direction: column;
                
                            transition: all 0.3s ease;
        
                            .desk-bg-card{
                                height: 100%;
                
                                padding: 4rem 1.5rem 0 1.5rem;
                                border-radius: 1rem;
                                position: relative;
                        
                                text-decoration: none;
                                color: inherit;
        
                                .desk-bg-card-image{
                                    width: 100%;
                                    height: auto;
                
                                    transition: transform ease-out 0.3s;
                                    border: 0px solid orange;
                                }
                                video{
                                    transition: transform ease-out 0.3s;
                                }
                                .card-content{
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-direction: column;
                                    width: 100%;
                                    // border: 1px solid red;
                        
                                    h4.card-title{
                                        font-size: 1.75rem;
                                        font-weight: lighter;
                                        color: white;
                                    }
                                    .card-title-line{
                                        margin: 1.25rem 0 1rem 0;
                                        height: 1px;
                                        width: 40px;
                                        background: $white-20;
                                    }
                                    .card-subtitle{
                                        color: $white-50;
                                        font-size: 0.75rem;
                                        letter-spacing: 0.5px;
                                        font-weight: 200;
                                        line-height: normal;
                                    }
                                }
                                img{
                                    position: absolute;
                                    width: 25%;
                                    bottom: 25%;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.5));
                                }
                            }                        
                            .big-card#notsure{
                                background: #F7F7F7;
                                
                                .card-content{
                                    h4{
                                        color: $dark;
                                    }
                                    .card-title-line{
                                        background: $dark-20;
                                    }
                                    p{
                                        color: $dark-50;
                                    }
                                }
                            }
                            .big-card#moonlog{
                                background: $dark;
                                overflow: hidden;
                                img{
                                    width: 62%;
                                    bottom: -7.5%;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                        
                            }
                            .big-card#react-stats{
                                background: #0880CF;
                                img{
                                    width: 40%;
                                    bottom: 27.5%;
                                    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.5));
                                }
                            }
                        }
    
                        &:hover{
                            .desk-top-card{
                                top: -6px; right: 6px;
                                bottom: 6px; left: -6px;
                            }
    
                            .desk-top-card:hover{
                                .desk-bg-card-image{
                                    // transform: scale(1.03);
                                }
                            }
                        }  
                    }
    
                    .desk-card .desk-top-card#Blockify{
                        overflow-x: visible;
                        overflow-y: visible;
                        
                        .desk-bg-card{
                            justify-content: flex-end;
                            border-radius: 0.95rem;
                            img{
                                margin-left: 4.5rem;
                                margin-bottom: 0;
                                width: 23rem;
                                transition: all ease-out 0.3s;
                            }
                        }
    
                        &:hover{
                            img{
                                margin-bottom: 5px;
                                transform: scale(1.03);
                            }
                        }
                    }
                    .desk-card .desk-top-card#Moonlog{
                        .desk-bg-card{
                            justify-content: center;
                            img{
                                height: 120%;
                                width: auto;
                                margin-bottom: -10%;
                                margin-left: 0%;
                            }
                        }
                    }
                }
            }
            //smooth-scroll
            .sectionIntro{
                height: 100vh;
                scroll-snap-align: end;
            }
            .no-scroll-snap{
                height: 250px;
                scroll-snap-align: start;
            }
            .footer{
                scroll-snap-align: end;
                z-index: 30;
            }
        }
    
        .project-desk-sec.odd {
            flex-direction: row-reverse;
    
    
            .project-desk-image .desk-card{
    
                .desk-bottom-card{
                    top: 6px; right: 6px;
                    bottom: -6px; left: -6px;
                }
    
                .desk-top-card:hover{
                    top: -6px; right: -6px;
                    bottom: 6px; left: 6px;
                }  
            }
        }
    }
}



.desk-top-card#hotbooks-spider{
    img{
        width: 20%;
    }
}

.desk-top-card#moonlog{
    background: $dark;
    img{
        position: absolute;
        width: 60%;
        bottom: 0;
    }
}


.desk-top-card#react-stats{
    background: #0880CF;
    img{
        width: 35%;
    }
}


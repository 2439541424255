


.trainings-desk{
    position: relative;
    height: 100%;
    width: 100vw;
    border: 0px solid magenta;
    background: white;

    .trainings-desk-container{
        margin: 0 5%;
        height: 100%;


        .training-desk-sec{
            height: 100vh;
            display: flex;
    
            .training-desk-text{
                height: 100%;
                width: 15rem;
                padding: 0 10%;
                border: 0px solid grey;
                flex: 1;
                
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;

                .training-desk-title{
                    font-size: 1.8rem;
                    color: $dark;
                }
                .training-desk-large{
                    font-size: 3rem;
                    font-weight: 200;
                    color: $dark-50;
                    margin: 2rem 0;
                }
                .training-desk-link{
                    font-size: 1.2rem;
                    color: $dark-80;
                    text-transform: lowercase;

                    display: inline-block;
                    position: relative;
                }

                .training-desk-link::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    transform: scaleX(0);
                    height: 2px;
                    bottom: 0;
                    left: 0;
                    background-color: $dark-80;
                    transform-origin: bottom center;
                    transition: transform 0.3s ease-out;
                  }
                  
                  .training-desk-link:hover::after {
                    transform: scaleX(1);
                    transform-origin: bottom center;
                  }
    
            }
            .training-desk-image{
                height: 100%;
                width: 15rem;
                border: 0px solid grey;
                flex: 1;
    
                display: grid;
                place-items: center;
    
                .desk-card{
                    border: 0px solid red;
    
                    position: relative;
                    cursor: pointer;
                    width: 20rem;
                    height: 20rem;
    
      
                    .desk-bottom-card{
                        position: absolute;
                        top: 6px; right: -6px;
                        bottom: -6px; left: 6px;
                        background: none;
                        border-radius: 1rem;
                        border: 1.5px solid hsl(0, 0%, 0%, 30%);
                    }

    
                    .desk-top-card{
                        position: absolute;
                        top: 0; right:0;
                        bottom: 0; left: 0;
                        background: white;
                        border-radius: 1rem;
                        border: 1.5px solid $dark-80;
                        overflow: hidden;
            
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: column;
            
                        transition: all 0.3s ease;
    
                        .desk-bg-card{
                            height: 100%;
                            border: 0px solid orange;
            
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            
                            .desk-bg-card-image{
                                width: 100%;
                                height: auto;
            
                                border: 0px solid orange;
                                display: none;
                            }

                            .border-animation {
                                position: absolute;
                                padding: 0;
                                margin: 0;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background: none;
                                overflow: hidden;
                              
                                display: grid;
                                place-items: center;
                                &::before {
                                  content: "";
                                  position: absolute;
                                  width: 139%;
                                  height: 50%;
                                  background: linear-gradient(140deg, #ec0045, #ffad55, #20a39e, #0033ff, #ffa72d, #ec0045);
                                  animation: spin 5s linear infinite;
                                }
                              
                                &::after {
                                  content: "";
                                  position: absolute;
                                  inset: 3px;
                                  background: white;
                                  border-top-right-radius: 5px;
                                  border-top-left-radius: 5px;
                                  display: block;
                                }
                    
                                &:hover::before{
                                    background: $dark;
                                }

                                @keyframes spin {
                                    0% {
                                      transform: rotate(0deg);
                                    }
                                    100% {
                                      transform: rotate(360deg);
                                    }
                                  }
                            }
                        }

                        #Blockify{
                            justify-content: flex-end;

                            img{
                                width: 35rem;
                            }
                        }
                    }
                    &:hover{
                        .desk-top-card{
                            top: -6px; right: 6px;
                            bottom: 6px; left: -6px;
                        }
                    }  
                }
            }
        }
    }
    



    .training-desk-sec:nth-child(even) {
        flex-direction: row-reverse;


        .training-desk-image .desk-card{

            .desk-bottom-card{
                top: 6px; right: 6px;
                bottom: -6px; left: -6px;
            }

            .desk-top-card:hover{
                top: -6px; right: -6px;
                bottom: 6px; left: 6px;
            }  
        }
    }
}
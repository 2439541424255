
.big-card-padding{
    height: 100%;
    width: 100%;
    padding-bottom: 1.5rem;
    display: grid;
    place-items: center;

    .big-card{
        height: 60vh;
        width: 100%;
        padding: 4rem 1.5rem 0 1.5rem;
        border: 1px solid black;
        border-radius: 1rem;
        position: relative;

        text-decoration: none;
        color: inherit;

        .card-content{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            // border: 1px solid red;

            h4.card-title{
                font-size: 1.75rem;
                font-weight: lighter;
                color: white;
            }
            .card-title-line{
                margin: 1.25rem 0 1rem 0;
                height: 1px;
                width: 40px;
                background: $white-20;
            }
            .card-subtitle{
                color: $white-50;
                font-size: 0.75rem;
                letter-spacing: 0.5px;
                font-weight: 200;
                line-height: normal;
            }
        }
        img{
            position: absolute;
            width: auto;
            height: 25%;
            bottom: 25%;
            left: 50%;
            transform: translateX(-50%);
            filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.5));
        }
    }

    .big-card#hotbooks-spider{
        background: white;
        border: 1px solid $dark;
        overflow: hidden;
        
        .card-content{
            h4.card-title{
                color: $dark;
            }
            .card-title-line{
                background: $dark-20;
            }
            p{
                color: $dark-50;
            }
        }
        // img{
        //     height: auto;
        //     width: 70%;
        //     bottom: 15%;
        // }
        img{
            height: auto;
            width: 90%;
            left: 20%;
            top: 65%;
            transform: none;
            transform: translate(-50%, -50%);
        }
    }
    .big-card#moonlog{
        background: linear-gradient(102.05deg, #1C2327 1.25%, #040608 100%);
        overflow: hidden;
        img{
            height: auto;
            width: 62%;
            bottom: -7.5%;
            left: 50%;
            transform: translateX(-50%);
        }

    }
    .big-card#react-stats{
        background: linear-gradient(101.5deg, #099CCE -1.29%, #1A17B4 184.24%);
        img{
            width: 40%;
            bottom: 27.5%;
            filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.5));
        }
    }

    .big-card#blog{
        background: linear-gradient(102.05deg, #222D33 1.25%, #0E141A 100%);
        overflow: hidden;
        img{
            width: auto;
            height: 50%;
            filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.5));
            bottom: -5%;
        }
    }

    .big-card#youtube{
        background: linear-gradient(281.48deg, #3E071A 0%, #B5130C 99.42%);
        img{
            width: auto;
            height: 50%;
            filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.5));
            bottom: -7.5%;
        }
    }
}




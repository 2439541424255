

.github{
    padding: 4.5rem 1.5rem;
    background: #F8F8F8;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    border-top: 0.5px solid $dark-10;
    border-bottom: 0.5px solid $dark-10;

    .button{
        height: 20rem;
        width: 20rem;
        margin-bottom: 0rem;

        .bottom-layer{
            top: 8px;
            right: -8px;
            bottom: -8px;
            left: 8px;
            border-radius: 10px;
        }
        .top-layer{
            border-radius: 10px;
            background: black;
            border: 1.5px solid rgba(0, 0, 0, 0.8);
         
            display: grid;
            place-items: center;

            img{
                width: 80%;
                height: auto;
                margin-bottom: -2px;
            }
        }

        &:hover {
            .top-layer {
              top: -8px;
              right: 8px;
              bottom: 8px;
              left: -8px;
            }
        }
    }
}



  
@media (min-width: $desktop) {
    .github{
        padding: 6rem 14%;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        gap: 7rem;


        .section-content{
            width: auto;
            gap: 1rem;
        }

        .button{
            min-width: 20rem;
            width: 20rem;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}
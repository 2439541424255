
.projects{
    position: relative;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: 1.5rem 1.5rem 7rem 1.5rem;

    display: grid;
    gap: 1.5rem;
    background: white;


    .projectcard{
        border: 0px solid red;

        position: relative;
        cursor: pointer;
        width: 100%;
        height: 295px;

        .bottom-card{
            position: absolute;
            top: 4px; right: 4px;
            bottom: -4px; left: -4px;
            background: none;
            border-radius: 5px;
            border: 1px solid hsl(0, 0%, 0%, 30%);
        }

        .top-card{
            position: absolute;
            top: 0; right:0;
            bottom: 0; left: 0;
            background: white;
            border-radius: 5px;
            border: 1px solid $dark-80;

            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;

            transition: all 0.3s ease;

            .bg-card{
                height: 40vh;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                overflow: hidden;

                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .bg-card-img{
                    position: absolute;
                    top: 0; bottom: 0;
                    right: 0; left: 0;
                    width: 100%;
                    height: 100%;
                    // background: url(../../public/assets/images/home/b2.png);
                    background-size: cover;
                    background-position: center;
                }
            }
            .content-card{
                height: 69%;
                border: 0px solid green;
                padding: 1rem;
                border-top: 1px solid $dark-80;

                display: flex;
                flex-direction: column;

                h4{
                    margin-bottom: 0.5rem;
                }
                p{
                    font-weight: 200;
                    font-size: 0.8rem;
                    color: $dark-50;
                }

                .tabs-pool{
                    margin-top: auto;
                    border: 0px solid red;
                    display: flex;
                    gap: 1rem;
                }

                .tabs-container{
                    height: 1.4rem;
                    width: auto;
                    border-radius: 3px;
                    border: 1px solid hsla(0, 0%, 50%, 0.5);
                    padding: 6px;
                    background: hsla(0, 0%, 50%, 0.01);

                    display: flex;
                    align-items: center;


                    .tabs-color{
                        height: 0.6rem;
                        width: 0.6rem;
                        background: hsla(0, 0%, 50%, 0.5);
                        margin-right: 6px;
                        border-radius: 1.5px;
                    }

                    .tabs-text{
                        color: black;
                        font-size: 0.5rem;
                    }
                }
            }
        }



        &:hover{
            .top-card{
                top: -4px; right: -4px;
                bottom: 4px; left: 4px;
            }
        }
    }
}



#Blockify{
    justify-content: flex-end;
    img{
        width: 50%;
        margin-left: -10%;
        margin-bottom: -2%;
    }
}

#Moonlog{
    img{
        width: 100%;
        margin-bottom: -10%;
        margin-left: 0%;
    }
}

#React-Stats{
    overflow: visible;
    img{
        position: absolute;
        top: 0;
        right: 1rem;
        z-index: 50;
        width: 40%;
    }
}

.bg-card#UX-100{
    background: none;
    // video{
    //     display: none;
    // }
    .case-background-image{
        display: none;
    }
}






.course-section{
    padding: 0 $spacing-phone;

    ul{
        list-style: none;
    }
    li{
        list-style-type: none;
        cursor: pointer;
    }

    .courses-title{
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        .courses-title-line{
            border-bottom: 1px solid $dark-20;
            width: 50px;
            display: grid;
            place-items: center;
            padding-top: 0.5rem;
        }
    }

    .block{
        background: white;
        height: 100%;
        border-radius: 5px;
        box-shadow: $box-shadow;

        margin-bottom: $spacing-phone;
        transition: all 200ms ease-out;

        a{
            text-decoration: none;
            color: $dark;
            font-family: $os;
        }

        .card-top{
            display: flex;
            padding: 1rem;
            .card-left{
                .card-logo{
                    height: 41px;
                    width: 41px;
                    background: url('../../public/assets/images/about/course-logos/ccd.png');
                    background-size: cover;
                    background-position: center;
                    box-shadow: $shadow-logo;
                    border-radius: 5px;
                }

                .coursera{
                    background: url('../../public/assets/images/about/course-logos/coursera_logo.png');
                    background-position: center; background-size: cover;
                }
                .google{
                    background: url('../../public/assets/images/about/course-logos/google.png');
                    background-position: center; background-size: cover;
                }
                .meta{
                    background: url('../../public/assets/images/about/course-logos/meta_logo.png');
                    background-position: center; background-size: cover;
                }
                .codecademy{
                    background: url('../../public/assets/images/about/course-logos/codecademy.png');
                    background-position: center; background-size: cover;
                }
                .cs50{
                    background: url('../../public/assets/images/about/course-logos/harvard.png');
                    background-position: center; background-size: cover;
                }
                .harvard{
                    background: url('../../public/assets/images/about/course-logos/harvard_pure.png');
                    background-position: center; background-size: cover;
                }
            }
            .card-right{
                margin-left: 1rem;
                .card-title{
                    font-family: $os;
                    font-weight: 500;
                    font-size: 0.8rem;
                    margin-bottom: 0.5rem;
                    letter-spacing: normal;
                    color: $dark; 
                }
                .card-teacher{
                    color: $dark-50;
                    font-size: 0.7rem;
                    font-weight: 400;
                    margin-bottom: 0.75rem;
                }
                div{
                    margin-bottom: 0.75rem;
                    display: flex;
                    align-items: center;
                    gap: 0.4rem;
                    border: 0px solid black;

                    .card-date, .card-grade{
                        font-family: $os;
                        font-weight: 400;
                        font-size: 0.8rem;
                        letter-spacing: normal;
                        color: $dark-80;
                    }
                }
                :last-child{
                    margin-bottom: 0;
                }
                .card-star{
                    width: 12px;
                    height: 12px;
                    background: url('../../public/assets/images/about/course-logos/star.svg');
                    background-position: center;
                    background-size: cover;
                    margin: auto 0;
                }
                .card-checkmark{
                    width: 11px;
                    height: 8.6px;
                    background: url('../../public/assets/images/about/course-logos/checkmark.svg');
                    background-position: center;
                    background-size: cover;
                    margin: auto 0;
                }
            }
        }
        .card-bottom{
            height: 45px;
            background: $light-share2;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;                                
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            

            .card-open{
                width: 14px;
                height: 14px;
                background: url('../../public/assets/images/about/course-logos/opengrey.png');
                background-position: center;
                background-size: cover;
            }
            p{
                font-size: 0.85rem;
                font-family: $os;
                font-weight: 500;
                color: $blue-share;
            }
        }
    }
}


@media (min-width: $mobile) {
    .course-section{
        padding: $spacing-mobile;
        .block{
            margin-bottom: $spacing-mobile;
        }
    }
}
@media (min-width: $mobile) {
    .course-section{
        padding: 2.5rem $spacing-tablet;
        .block{
            margin-bottom: 2.5rem;
        }
    }
}
@media (min-width: $laptop) {
    .course-section{
        padding: 3rem $spacing-laptop;
        .block{
            margin-bottom: 3rem;
            .card-top{
                padding: 1.5rem;
                .card-left{
                    .card-logo{
                        height: 60px;
                        width: 60px;
                    }
                }
                .card-right{
                    margin-left: 1.5rem;
                    .card-title{
                        font-size: 1.05rem;
                        margin-bottom: 0.7rem;
                    }
                    .card-teacher{
                        font-size: 0.8rem;
                        margin-bottom: 1rem;
                    }
                    h6{
                        font-size: 0.8rem;
                        margin-bottom: 0.8rem;
                    }
                }
            }
        }
        .block:hover{
            box-shadow: $box-shadow-hover;
            transform: scale(1.02) !important;
        }
    }
}
@media (min-width: $desktop) {
    .course-section{
        padding: 0 $spacing-desktop;
        .block{
            margin-bottom: 3rem;
        }
    }
}


.homehero{
    background: linear-gradient(149deg, #FBFBFD 0%, rgba(255,255,255,1) 87%, rgba(255,255,255,1) 100%);
    background: #FBFBFD;
    border-bottom: 0.5px solid hsla(0deg, 20%, 5%, 0.1);
    
    

    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0; 
    height: 100vh;
    display: grid;
    place-items: center;

    h1{
        font-size: 5rem;
    }
}

.placeholder{
    scroll-snap-align: start;
    height: 100vh;
    width: 100vw;
    background: none;
    opacity: 0.1;
}
